import React from 'react';

export type SelfReportedMedicationsDetails = {
  hasNewSelfReportedMedications?: boolean;
  newSelfReportedMedications?: string;
  existingSelfReportedMedicationsChanged?: boolean;
  existingSelfReportedMedicationsChanges?: string;
};

type SelfReportedMedicationsProps = {
  visitReasonDetails: SelfReportedMedicationsDetails;
};

export const SelfReportedMedications = ({ visitReasonDetails }: SelfReportedMedicationsProps) => {
  const {
    hasNewSelfReportedMedications,
    newSelfReportedMedications,
    existingSelfReportedMedicationsChanged,
    existingSelfReportedMedicationsChanges,
  } = visitReasonDetails;

  if (!hasNewSelfReportedMedications && !existingSelfReportedMedicationsChanged) {
    return null;
  }

  return (
    <>
      {existingSelfReportedMedicationsChanged && (
        <li>
          <span className="label">Outside Med Change(s):</span>{' '}
          {existingSelfReportedMedicationsChanges}
        </li>
      )}
      {hasNewSelfReportedMedications && (
        <li>
          <span className="label">Outside Med Addition(s):</span> {newSelfReportedMedications}
        </li>
      )}
    </>
  );
};
