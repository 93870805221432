import { makeStyles } from '@material-ui/core/styles';

import Colors from 'src/common/ui/base/Colors';

export const useStyles = makeStyles(() => ({
  sectionContainer: {
    marginTop: 20,
  },
  sectionLabel: {
    color: Colors.CoolGray7,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 1.45,
    letterSpacing: '1.68px',
    textTransform: 'uppercase',
  },
  highlight: {
    padding: '14px',
    backgroundColor: Colors.Yellow1,
    border: `2px solid ${Colors.Yellow2}`,
    borderRadius: '10px',
  },
}));
