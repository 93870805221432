import React from 'react';

type Maybe<T> = T | null | undefined;

export type RefillsDetails = {
  adequateBupe?: boolean;
  needRefill?: boolean;
  outOf?: Maybe<string[]>;
};

type RefillsProps = {
  visitReasonDetails: RefillsDetails & { version?: string };
};

const formatRefillItems = (rx: string, adequateBupe?: boolean) =>
  rx.match(/buprenorphine/i) && !adequateBupe ? `${rx} (out)` : rx;

export const Refills = ({ visitReasonDetails }: RefillsProps) => {
  const { adequateBupe, needRefill, outOf, version } = visitReasonDetails;

  if (!needRefill) {
    return null;
  }

  const medicationList = outOf ?? [];
  const medicationText = (
    !version ? medicationList.map(rx => formatRefillItems(rx, adequateBupe)) : medicationList
  ).join(', ');

  return (
    <li>
      <span className="label">Needs refill:</span> {medicationText}
    </li>
  );
};
