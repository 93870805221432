import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import { EmptyCardClassification } from 'src/generated/gql/graphql';
import Colors from 'src/nightingale/Colors';
import { ReactComponent as MedicalIcon } from 'src/shared/icons/iconMedical.svg';
import { ReactComponent as SupportIcon } from 'src/shared/icons/iconSupport.svg';

const useStyles = makeStyles({
  centeringContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',

    '& svg': {
      marginRight: 24,
    },
  },
  textArea: {
    fontFamily: '"Nunito", "Nunito Sans"',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: Colors.Taupe,

    '& .topLine': {
      fontSize: 16,
      letterSpacing: '0.201px',
    },

    '& .bottomLine': {
      textTransform: 'uppercase',
      fontSize: 12,
      letterSpacing: '1.44px',
    },
  },
});

type EmptyFocusCardProps = {
  type: EmptyCardClassification;
  providerName?: string | null;
};

const MessageMap = {
  [EmptyCardClassification.AfterEndOfDay]: () => ({
    topLine: 'You’re all done for the day!',
    bottomLine: 'Great job today!',
  }),
  [EmptyCardClassification.Pause]: () => ({
    topLine: 'Take a moment to breathe',
    bottomLine: 'Enjoy this tranquil pause',
  }),
  [EmptyCardClassification.BeforeStartOfDay]: (hour: number, providerName?: string | null) => {
    let timeOfDay = 'morning';
    if (hour >= 12 && hour < 17) {
      timeOfDay = 'afternoon';
    } else if (hour >= 17) {
      timeOfDay = 'evening';
    }
    return {
      topLine: providerName ? `Good ${timeOfDay}, ${providerName}!` : `Good ${timeOfDay}}!`,
      bottomLine: 'Let’s get started!',
    };
  },
  [EmptyCardClassification.PatientBeingPrepped]: () => ({
    topLine: 'A patient is almost done with their prep',
    bottomLine: 'They will be suggested to you soon',
  }),
};

export const EmptyFocusCard = (props: EmptyFocusCardProps) => {
  const classes = useStyles();
  const messageText = MessageMap[props.type](new Date().getHours(), props.providerName);
  const Icon =
    props.type === EmptyCardClassification.PatientBeingPrepped ? MedicalIcon : SupportIcon;

  return (
    <div className={classes.centeringContainer}>
      <div className={classes.content}>
        <SvgIcon component={Icon} style={{ width: 48, height: 48 }} viewBox="0 0 48 48" />
        <div className={classes.textArea}>
          <div className="topLine">{messageText.topLine}</div>
          <div className="bottomLine">{messageText.bottomLine}</div>
        </div>
      </div>
    </div>
  );
};
