import React, { createContext, useState } from 'react';

export const STORAGE_ITEM_NAME = 'lastVisits';

export type MiniEvent = {
  allDay: boolean;
  duration: number | null;
  id: string;
  start: Date;
};

type EventsByPatient = Record<string, MiniEvent[]>;

const HISTORY_LENGTH = 3;

export const LastPatientVisitContext = createContext<{
  addLastPatientVisit: (patientId: string, event: MiniEvent) => void;
  lastPatientVisits: EventsByPatient;
}>({
  addLastPatientVisit: () => {},
  lastPatientVisits: {},
});

export const LastPatientVisitContextProvider: React.FC = ({ children }) => {
  const [lastPatientVisits, setLastPatientVisits] = useState<EventsByPatient>(
    getLastVisitsFromSessionStorage(),
  );

  const addLastPatientVisit = (patientId: string, event: MiniEvent) => {
    const events = lastPatientVisits[patientId]?.length ? lastPatientVisits[patientId] : [];
    if (events.some(e => e.id === event.id)) return;

    // Add the new visit to the beginning of the list and keep the next (N - 1) visits (where
    // N = HISTORY_LENGTH), dropping any remaining visits off the end of the list
    const updatedLastPatientVisits = {
      ...lastPatientVisits,
      [patientId]: [event, ...events.slice(0, HISTORY_LENGTH - 1)],
    };

    sessionStorage.setItem(STORAGE_ITEM_NAME, JSON.stringify(updatedLastPatientVisits));

    setLastPatientVisits(updatedLastPatientVisits);
  };

  return (
    <LastPatientVisitContext.Provider
      value={{
        addLastPatientVisit,
        lastPatientVisits,
      }}
    >
      {children}
    </LastPatientVisitContext.Provider>
  );
};

/**
 * Gets the last visit map from sessionStorage, if it exists.
 */
const getLastVisitsFromSessionStorage = (): EventsByPatient => {
  const lastVisitStorageItem = sessionStorage.getItem(STORAGE_ITEM_NAME);

  if (!lastVisitStorageItem) return {};

  const lastVisit: EventsByPatient = JSON.parse(lastVisitStorageItem);

  if (!lastVisit) return {};

  const parsedVisitData: EventsByPatient = {};

  Object.entries(lastVisit).forEach(lastVisitEntry => {
    const [visitKey, visitValue] = lastVisitEntry;
    const parsedEvents = visitValue.map(lastVisitItem => {
      return {
        ...lastVisitItem,
        start: new Date(lastVisitItem.start),
      };
    });
    parsedVisitData[visitKey] = parsedEvents;
  });

  return parsedVisitData;
};
