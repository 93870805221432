import React from 'react';

import {
  OtherMedicationConcerns,
  OtherMedicationConcernsDetails,
} from 'src/dropInClinic/visitReason/OtherMedicationConcerns';
import { Question, QuestionDetails } from 'src/dropInClinic/visitReason/Question';
import { Refills, RefillsDetails } from 'src/dropInClinic/visitReason/Refills';
import {
  SelfReportedMedications,
  SelfReportedMedicationsDetails,
} from 'src/dropInClinic/visitReason/SelfReportedMedications';
import { SideEffects, SideEffectsDetails } from 'src/dropInClinic/visitReason/SideEffects';

type VisitReasonProps = {
  visitReasonDetails: OtherMedicationConcernsDetails &
    QuestionDetails &
    RefillsDetails &
    SideEffectsDetails &
    SelfReportedMedicationsDetails;
};

const reasonComponents = [
  Refills,
  SideEffects,
  OtherMedicationConcerns,
  SelfReportedMedications,
  Question,
];

export const VisitReason = ({ visitReasonDetails }: VisitReasonProps) => {
  return (
    <ul>
      {reasonComponents.map(Component => (
        <Component key={Component.name} visitReasonDetails={visitReasonDetails} />
      ))}
    </ul>
  );
};
