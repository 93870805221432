import { Box } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import AccessTime from '@material-ui/icons/AccessTime';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { differenceInYears, format, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import isNil from 'lodash/isNil';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import { VOICEMAIL_CONSENT_OPTIONS } from 'src/components/forms/schemas/definitions';
import BupeRunOut from 'src/components/general/PatientMenu/highlights/BupeRunOut';
import CopyPatientId from 'src/components/general/PatientMenu/highlights/CopyPatientId';
import DrugScreeningPeriodStatus from 'src/components/general/PatientMenu/highlights/DrugScreeningPeriodStatus';
import Fyi from 'src/components/general/PatientMenu/highlights/Fyi';
import LastCompletedVisitDate from 'src/components/general/PatientMenu/highlights/LastCompletedVisitDate';
import VisitCadence from 'src/components/general/PatientMenu/highlights/VisitCadence';
import ZendeskProfileLink from 'src/components/general/PatientMenu/highlights/ZendeskProfileLink';
import { LOAD_MENU_PATIENT } from 'src/components/general/PatientMenu/queries.gql';
import { ProfileAvatar } from 'src/components/general/ProfileAvatar';
import PhoneNumber from 'src/components/general/phoneNumber';
import { RouterLinkDiv } from 'src/components/general/routerLink';
import useTypedSWR from 'src/components/general/useTypedSWR';
import { LastPatientVisitContext } from 'src/events/LastPatientVisitContext';
import {
  POC_TESTING,
  REMOVE_LEGACY_CHAT_STAGE2_HIDE_DISPLAY,
  SHOW_PATIENT_PEBBLES_TABLE,
  TURN_OFF_PEBBLES,
  ZENDESK_PATIENT_SIDEBAR_LINK,
} from 'src/featureFlags/currentFlags';
import { MenuPatient } from 'src/generated/gql/graphql';
import Colors from 'src/nightingale/Colors';
import PatientPebblesCountBadge from 'src/pebbles/components/PatientPebblesCountBadge';
import PatientPebblesCountProvider from 'src/pebbles/components/PatientPebblesCountContext/PatientPebblesCountProvider';
import { getFullName } from 'src/shared/stores/resource';
import { getStartTime } from 'src/shared/util/events';
import type { RootStore } from 'src/stores/root';
import { colors } from 'src/util/colors';
import { inject } from 'src/util/inject';
import { PEBBLE_STATUS_MAP } from 'src/util/pebbles';
import { getStateAbbreviation } from 'src/util/usStates';

const formatPatientLocation = (
  patient: Pick<MenuPatient, 'homeAddress' | 'timezone'> | undefined,
): { firstLine: string; secondLine: string } => {
  if (!patient) {
    return { firstLine: '', secondLine: '' };
  }

  const firstLine = [patient.homeAddress?.city, getStateAbbreviation(patient.homeAddress?.state)]
    .filter(locationString => !isNil(locationString) && locationString !== '')
    .join(', ');
  const secondLine = [
    patient.homeAddress?.zip,
    patient.timezone ? `(${formatInTimeZone(new Date(), patient.timezone, 'z')})` : null,
  ]
    .filter(locationString => !isNil(locationString))
    .join(', ');

  return { firstLine, secondLine };
};

const usePatientInfoStyles = makeStyles({
  patientInfo: {
    color: Colors.Gray7,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: 0.2,
    marginTop: 10,
  },
});

const PatientInfo: React.FC<{ 'data-testid'?: string; title?: string }> = ({
  children,
  'data-testid': testId,
  title,
}) => {
  const classes = usePatientInfoStyles();
  return (
    <Typography component="div" className={classes.patientInfo} data-testid={testId} title={title}>
      {children}
    </Typography>
  );
};

const usePatientNameStyles = makeStyles({
  patientName: {
    color: Colors.Gray7,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.1,
    marginTop: 10,

    '&:first-child': {
      marginTop: 0,
    },

    '& small': {
      fontWeight: 'normal',
      fontSize: 12,
      color: Colors.Gray7,
    },
  },
});

const PatientName: React.FC<{ title?: string }> = ({ children, title }) => {
  const classes = usePatientNameStyles();
  return (
    <Typography className={classes.patientName} title={title}>
      {children}
    </Typography>
  );
};

const PatientMenu = ({
  patientId,
  activeTab,
  activeEventId,
  rootStore: {
    auth,
    generateRouteUrl,
    patients: { calendar, chat: participantChat },
  },
}: {
  patientId: string;
  activeTab: string;
  activeEventId?: string;
  rootStore: RootStore;
}) => {
  const { data } = useTypedSWR([LOAD_MENU_PATIENT, { id: patientId }], {
    revalidateOnFocus: false,
  });
  const { lastPatientVisits } = useContext(LastPatientVisitContext);
  const flags = useContext(FeatureFlagContext);

  const patient = data?.menuPatient ?? undefined;

  const patientLocation = formatPatientLocation(patient);

  let voicemailConsentLabel = VOICEMAIL_CONSENT_OPTIONS.unknown;

  if (patient?.consentsToVoicemails === true) {
    voicemailConsentLabel = VOICEMAIL_CONSENT_OPTIONS.granted;
  } else if (patient?.consentsToVoicemails === false) {
    voicemailConsentLabel = VOICEMAIL_CONSENT_OPTIONS.not_granted;
  }

  const activeTabValue = activeTab === 'events' ? `visit-${activeEventId}` : activeTab;

  const classes = useStyles();

  return (
    <nav className={classes.container} data-testid="participant-menu-container">
      {patient?.id && (
        <Paper className={classes.content} elevation={2}>
          <aside className={classes.participantInfo} data-testid="participant-info">
            {patient.profilePhoto && (
              <ProfileAvatar
                photoUrl={patient.profilePhoto}
                altText={getFullName(patient)}
                verificationStatus={patient?.verificationStatus}
              />
            )}
            <PatientName title="Preferred Name">
              {getFullName(patient)}
              {patient.pronouns && <small title="Pronouns">{` (${patient.pronouns})`}</small>}
              {patient.isOnPayorWaitlist && (
                <span>
                  {' '}
                  <AccessTime fontSize="small" />
                </span>
              )}
            </PatientName>
            {patient.dob && (
              <PatientInfo>
                {format(parseISO(patient.dob), 'MM/dd/yyyy')}
                <br />
                {`(Age ${differenceInYears(new Date(), parseISO(patient.dob))})`}
              </PatientInfo>
            )}
            {patient.phone && (
              <PatientInfo>
                <PhoneNumber number={patient.phone} />
                <br />
                <em>Voicemails: {voicemailConsentLabel}</em>
              </PatientInfo>
            )}
            <PatientInfo data-testid="patient-location">
              <span>{patientLocation.firstLine}</span>
              {patientLocation.firstLine && ', '}
              <span>{patientLocation.secondLine}</span>
            </PatientInfo>
            {!flags[ZENDESK_PATIENT_SIDEBAR_LINK] ? (
              <PatientInfo>
                <button
                  className={classes.copyPatientID}
                  onClick={() => {
                    navigator.clipboard.writeText(patient.id);
                  }}
                  type="button"
                >
                  copy patient ID
                </button>
              </PatientInfo>
            ) : null}
            <PatientInfo>
              <Box display="flex" flexDirection="column" gridGap="4px">
                {flags[ZENDESK_PATIENT_SIDEBAR_LINK] ? (
                  <Box display="flex" gridGap="4px">
                    <Box>
                      <CopyPatientId patientId={patient.id} />
                    </Box>
                    <Box>
                      <ZendeskProfileLink url={patient.zendeskUrl} />
                    </Box>
                  </Box>
                ) : null}
                <Box display="flex" gridGap="4px">
                  <Box flexGrow={1}>
                    <Fyi patientId={patient.id} />
                  </Box>
                  <Box flexGrow={2}>
                    <VisitCadence patientId={patient.id} />
                  </Box>
                </Box>
                <Box>
                  <BupeRunOut patientId={patient.id} />
                </Box>
                <Box>
                  <DrugScreeningPeriodStatus patientId={patient.id} />
                </Box>
                <Box>
                  <LastCompletedVisitDate patientId={patient.id} />
                </Box>
              </Box>
            </PatientInfo>
          </aside>
          <Tabs
            className={classes.tabs}
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
            orientation="vertical"
            variant="scrollable"
            value={activeTabValue}
          >
            <Tab
              className={classNames(classes.tab, {
                [classes.selectedTab]: activeTab === 'overview',
                [classes.unselectedTab]: activeTab !== 'overview',
              })}
              component={RouterLinkDiv}
              routeName="showPatient"
              params={{ id: patient.id, tab: 'overview' }}
              label="Overview"
              title="Overview"
              value="overview"
            />
            <Tab
              className={
                activeTab === 'activity'
                  ? classNames(classes.tab, classes.selectedTab)
                  : classNames(classes.unselectedTab, classes.tab)
              }
              component={RouterLinkDiv}
              routeName="showPatient"
              params={{ id: patient.id, tab: 'activity' }}
              label="Activity"
              title="Activity"
              value="activity"
            />
            <Tab
              className={
                activeTab === 'calendar'
                  ? classNames(classes.tab, classes.selectedTab)
                  : classNames(classes.unselectedTab, classes.tab)
              }
              component={RouterLinkDiv}
              routeName="showPatient"
              params={{
                id: patient.id,
                tab: 'calendar',
              }}
              queryParams={{ date: calendar.currentDateQueryParam, view: calendar.currentView }}
              label="Calendar"
              title="Calendar"
              value="calendar"
            />
            {lastPatientVisits[patient.id]?.length > 0
              ? [...lastPatientVisits[patient.id]]
                  // sort operates on the array in-place, which we don't want, so make a copy first
                  .sort((a, b) => (a.start && b.start ? a.start.getTime() - b.start.getTime() : 0))
                  .map(lastEvent => {
                    const start = getStartTime(lastEvent);
                    const formattedDate = format(start, 'MM/dd/yy');
                    const formattedDateTime = format(lastEvent.start, 'MM/dd/yyyy h:mm a');
                    const titleDateString = lastEvent.allDay ? formattedDate : formattedDateTime;

                    return (
                      <Tab
                        key={lastEvent.id}
                        className={
                          activeTab === `visit-${lastEvent.id}`
                            ? classNames(classes.tab, classes.subTab, classes.selectedTab)
                            : classNames(classes.tab, classes.subTab, classes.unselectedTab)
                        }
                        component={RouterLinkDiv}
                        routeName="showPatient"
                        params={{
                          id: patient.id,
                          tab: 'events',
                          tabItem: lastEvent.id,
                        }}
                        label={`– ${lastEvent.start ? formattedDate : 'Visit'}`}
                        title={`– ${lastEvent.start ? titleDateString : 'Visit'}`}
                        value={`visit-${lastEvent.id}`}
                      />
                    );
                  })
              : null}
            {flags[POC_TESTING] ? (
              <Tab
                className={
                  activeTab === 'pocTesting'
                    ? classNames(classes.tab, classes.selectedTab)
                    : classNames(classes.unselectedTab, classes.tab)
                }
                component={RouterLinkDiv}
                routeName="showPatient"
                params={{
                  id: patient.id,
                  tab: 'pocTesting',
                }}
                label="POC Testing"
                title="POC Testing"
                value="pocTesting"
              />
            ) : null}
            <Tab
              className={
                activeTab === 'documents'
                  ? classNames(classes.tab, classes.selectedTab)
                  : classNames(classes.unselectedTab, classes.tab)
              }
              component={RouterLinkDiv}
              routeName="showPatient"
              params={{ id: patient.id, tab: 'documents' }}
              label="Documents"
              title="Documents"
              value="documents"
            />
            {/* @deprecated: chat feature has been retired. Delete with flag */}
            {!flags[REMOVE_LEGACY_CHAT_STAGE2_HIDE_DISPLAY] ? (
              <Tab
                className={
                  activeTab === 'conversation'
                    ? classNames(classes.tab, classes.selectedTab)
                    : classNames(classes.unselectedTab, classes.tab)
                }
                component={RouterLinkDiv}
                routeName="showPatient"
                params={{
                  id: patient.id,
                  tabItem: participantChat?.defaultConversationWithCurrentUser?.id,
                  tab: 'conversation',
                }}
                label={
                  <Badge
                    badgeContent="•"
                    color="error"
                    data-testid="participant-menu-chat-badge"
                    invisible={
                      !auth.user ||
                      !('unreadMessageNotifications' in auth.user) ||
                      auth.user.unreadMessageNotifications === false ||
                      !participantChat?.defaultConversationWithCurrentUser ||
                      !participantChat?.defaultConversationWithCurrentUser.hasUnread
                    }
                  >
                    Chat
                  </Badge>
                }
                title="Chat"
                value="conversation"
              />
            ) : null}
            {!flags[TURN_OFF_PEBBLES] &&
              (flags[SHOW_PATIENT_PEBBLES_TABLE] ? (
                <Tab
                  className={
                    activeTab === 'pebbles'
                      ? classNames(classes.tab, classes.selectedTab)
                      : classNames(classes.unselectedTab, classes.tab)
                  }
                  component={RouterLinkDiv}
                  routeName="showPatient"
                  params={{ id: patient.id, tab: 'pebbles' }}
                  label={
                    flags[SHOW_PATIENT_PEBBLES_TABLE] ? (
                      <PatientPebblesCountProvider patientId={patientId}>
                        <PatientPebblesCountBadge />
                      </PatientPebblesCountProvider>
                    ) : (
                      'Pebbles'
                    )
                  }
                  title="Pebbles"
                  value="pebbles"
                />
              ) : (
                <Tab
                  className={classNames(classes.unselectedTab, classes.tab)}
                  onClick={() => {
                    const generatedUrl = generateRouteUrl(
                      'pebbles',
                      {},
                      {
                        page: 0,
                        participant: patient.id,
                        status: Object.keys(PEBBLE_STATUS_MAP).filter(
                          status => status !== 'completed' && status !== 'wont_do',
                        ),
                      },
                    );
                    window.open(generatedUrl, '_blank');
                  }}
                  label="Pebbles"
                  title="Pebbles"
                />
              ))}
            <Tab
              className={
                activeTab === 'tasks'
                  ? classNames(classes.tab, classes.selectedTab)
                  : classNames(classes.unselectedTab, classes.tab)
              }
              component={RouterLinkDiv}
              routeName="showPatient"
              params={{
                id: patient.id,
                tab: 'tasks',
              }}
              label="Tasks"
              title="Tasks"
              value="tasks"
            />
            <Tab
              className={
                activeTab === 'clinicActions'
                  ? classNames(classes.tab, classes.selectedTab)
                  : classNames(classes.unselectedTab, classes.tab)
              }
              component={RouterLinkDiv}
              routeName="showPatient"
              params={{
                id: patient.id,
                tab: 'clinicActions',
              }}
              label="Clinic"
              title="Clinic"
              value="clinicActions"
            />
            <Tab
              className={
                activeTab === 'erx'
                  ? classNames(classes.tab, classes.selectedTab)
                  : classNames(classes.unselectedTab, classes.tab)
              }
              component={RouterLinkDiv}
              routeName="showPatient"
              params={{
                id: patient.id,
                tab: 'erx',
              }}
              label="e-Rx"
              title="e-Rx"
              value="erx"
            />
          </Tabs>
        </Paper>
      )}
    </nav>
  );
};

const LETTER_SPACING = 0.3;
const useStyles = makeStyles({
  container: {
    width: 130,
  },
  content: {
    height: 'calc(100vh - 64px)',
    overflowY: 'auto',
    top: 64,
    borderRadius: 0,
    paddingTop: 0,
    position: 'sticky',
  },
  participantInfo: {
    marginTop: 10,
    marginLeft: 16,
    marginRight: 16,
    textAlign: 'left',
  },
  tabs: {
    width: 130,
    borderTop: `1px solid ${Colors.ChartGray}`,
    marginTop: 16,
  },
  unselectedTab: {
    color: Colors.Gray5,
    '&:hover': {
      color: Colors.BoulderBlue,
    },
  },
  tab: {
    minWidth: 130, // Override material UI default
    width: 130,
    padding: 0,
    paddingTop: 3, // Visually center text
    minHeight: 40,
    letterSpacing: LETTER_SPACING * 3,
    fontSize: 13,
    fontFamily: '"Nunito", "Nunito Sans"',
    fontWeight: 'bold',
    '& > *': {
      alignItems: 'flex-start', // Override material UI default
      paddingLeft: 20,
    },
  },
  subTab: {
    minHeight: 30,
    backgroundColor: Colors.Gray2,
    color: Colors.Gray6,
  },
  selectedTab: {
    backgroundColor: Colors.BlueSpruce,
    color: Colors.White,
  },
  name: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.taupe,
    letterSpacing: LETTER_SPACING,
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto',
    paddingLeft: 5,
  },
  copyPatientID: {
    appearance: 'none',
    background: 'none',
    border: 'none',
    color: 'inherit',
    cursor: 'pointer',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    margin: 0,
    padding: 0,
    textDecoration: 'underline',

    '&:hover': {
      color: Colors.BlueSpruce,
    },
  },
});

export default inject<typeof PatientMenu>('rootStore')(observer(PatientMenu));
